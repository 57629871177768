
import { defineComponent, onMounted } from "vue";
import { useToast } from "vue-toastification";
import { useAuthStore } from "@/core/stores/AuthStore";
import { useLocalNotificationsStore } from "@/core/stores/LocalNotificationsStore";

export default defineComponent({
  name: "app",
  data() {
    return {
      subscribedToNotifications: false
    }
  },
  methods: {
    subscribeToNotifications() {
      if (this.subscribedToNotifications) { return; }

      this.notificationsStore.addLocalNotificationsObserver({ callback: this.onNotification });
      this.subscribedToNotifications = true;
    },
    onNotification(notification) {
      if (notification.type == 'info') {
        this.toast.info(notification.content);
      } else if (notification.type == 'warning') {
        this.toast.warning(notification.content);
      } else if (notification.type == 'success') {
        this.toast.success(notification.content);
      } else if (notification.type == 'error') {
        this.toast.error(notification.content);
      }
    }
  },
  mounted() {
    this.subscribeToNotifications();
  },
  beforeMount() {
    useAuthStore().initialize();
  },
  setup() {
    const notificationsStore = useLocalNotificationsStore();
    const toast = useToast();

    return {
      notificationsStore,
      toast
    };
  },
});
