import { defineStore } from 'pinia'
import { markRaw, Raw } from 'vue';
import ApiService from "@/core/services/ApiService";
import { useLocalNotificationsStore } from '@/core/stores/LocalNotificationsStore';
import { AxiosRequestConfig } from 'axios';
import { parseErrorMessage } from '@/core/helpers/ErrorsHelper.js';
import { Organization, Role, VRouteRequest, VRouteResposne, ViaPointsTemplate } from '../models/Models';
import i18n from '../plugins/i18n';

export const useAPIStore = defineStore('APIStore', {
    actions: {
        showError(errorResponse) {
            const notificationsStore = useLocalNotificationsStore();
            const message = parseErrorMessage(errorResponse.data, i18n.global);
            notificationsStore.publishLocalNotification({
                type: 'error',
                content: message
            });
        },
        // MARK: - App versions
        async getAllAppVersions() {
            return new Promise<any>((resolve, reject) => {
                ApiService.setHeader();
                ApiService.get('app-versions').then(({ data }) => {
                    resolve(data);
                }).catch(({ response }) => {
                    this.showError(response);
                    reject(response);
                });
            });
        },
        async getAppVersion(data) {
            return new Promise<any>((resolve, reject) => {
                ApiService.setHeader();
                ApiService.get('app-versions/' + data.id).then(({ data }) => {
                    resolve(data);
                }).catch(({ response }) => {
                    this.showError(response);
                    reject(response);
                });
            });
        },
        async createAppVersion(data) {
            return new Promise<any>((resolve, reject) => {
                ApiService.setHeader();
                ApiService.post('app-versions', data.item).then(({ data }) => {
                    resolve(data);
                }).catch(({ response }) => {
                    this.showError(response);
                    reject(response);
                });
            });
        },
        async updateAppVersion(data) {
            return new Promise<any>((resolve, reject) => {
                ApiService.setHeader();
                ApiService.put('app-versions/' + data.id, data.item).then(({ data }) => {
                    resolve(data);
                }).catch(({ response }) => {
                    this.showError(response);
                    reject(response);
                });
            });
        },
        async deleteAppVersion(data) {
            return new Promise<any>((resolve, reject) => {
                ApiService.setHeader();
                ApiService.delete('app-versions/' + data.id).then(({ data }) => {
                    resolve(data);
                }).catch(({ response }) => {
                    this.showError(response);
                    reject(response);
                });
            });
        },
        // MARK: - Cargo
        async createCargo(vehicle_id, data) {
            return new Promise<any>((resolve, reject) => {
                ApiService.setHeader();
                ApiService.post(`vehicles/${vehicle_id}/cargo`, data).then(({ data }) => {
                    resolve(data);
                }).catch(({ response }) => {
                    this.showError(response);
                    reject(response);
                });
            });
        },
        async getAllVehiclesCargo(data: AxiosRequestConfig | undefined = undefined) {
            return new Promise<any>((resolve, reject) => {
                ApiService.setHeader();
                ApiService.get('vehicles/all/cargo', data).then(({ data }) => {
                    resolve(data);
                }).catch(({ response }) => {
                    this.showError(response);
                    reject(response);
                });
            });
        },
        async getVehicleCargo(data) {
            return new Promise<any>((resolve, reject) => {
                ApiService.setHeader();
                ApiService.get(`vehicles/${data.id}/cargo`).then(({ data }) => {
                    resolve(data);
                }).catch(({ response }) => {
                    this.showError(response);
                    reject(response);
                });
            });
        },
        async updateCargo(vehicle_id, data) {
            return new Promise<any>((resolve, reject) => {
                ApiService.setHeader();
                ApiService.put(`vehicles/${vehicle_id}/cargo/${data.id}`, data).then(({ data }) => {
                    resolve(data);
                }).catch(({ response }) => {
                    this.showError(response);
                    reject(response);
                });
            });
        },
        async updateCargoOrder(vehicle_id, data) {
            return new Promise<any>((resolve, reject) => {
                ApiService.setHeader();
                ApiService.put(`vehicles/${vehicle_id}/cargo/order`, data).then(({ data }) => {
                    resolve(data);
                }).catch(({ response }) => {
                    this.showError(response);
                    reject(response);
                });
            });
        },
        async deleteCargo(vehicle_id, data) {
            return new Promise<any>((resolve, reject) => {
                ApiService.setHeader();
                ApiService.delete(`vehicles/${vehicle_id}/cargo/${data.id}`).then(({ data }) => {
                    resolve(data);
                }).catch(({ response }) => {
                    this.showError(response);
                    reject(response);
                });
            });
        },
        // MARK: - Locations
        async findLocation(data) {
            return new Promise<any>((resolve, reject) => {
                ApiService.setHeader();
                ApiService.post('locations/search', data).then(({ data }) => {
                    resolve(data);
                }).catch(({ response }) => {
                    this.showError(response);
                    reject(response);
                });
            });
        },
        // MARK: - Navigation units
        async getAllNavigationUnits() {
            return new Promise<any>((resolve, reject) => {
                ApiService.setHeader();
                ApiService.get('navigation-units').then(({ data }) => {
                    resolve(data);
                }).catch(({ response }) => {
                    this.showError(response);
                    reject(response);
                });
            });
        },
        async getNavigationUnit(data) {
            return new Promise<any>((resolve, reject) => {
                ApiService.setHeader();
                ApiService.get('navigation-units/' + data.id).then(({ data }) => {
                    resolve(data);
                }).catch(({ response }) => {
                    this.showError(response);
                    reject(response);
                });
            });
        },
        async createNavigationUnit(data) {
            return new Promise<any>((resolve, reject) => {
                ApiService.setHeader();
                ApiService.post('navigation-units/', data.item).then(({ data }) => {
                    resolve(data);
                }).catch(({ response }) => {
                    this.showError(response);
                    reject(response);
                });
            });
        },
        async updateNavigationUnit(data) {
            return new Promise<any>((resolve, reject) => {
                ApiService.setHeader();
                ApiService.put('navigation-units/' + data.id, data.item).then(({ data }) => {
                    resolve(data);
                }).catch(({ response }) => {
                    this.showError(response);
                    reject(response);
                });
            });
        },
        async deleteNavigationUnit(data) {
            return new Promise<any>((resolve, reject) => {
                ApiService.setHeader();
                ApiService.delete('navigation-units/' + data.id).then(({ data }) => {
                    resolve(data);
                }).catch(({ response }) => {
                    this.showError(response);
                    reject(response);
                });
            });
        },
        // MARK: - Organizations
        async getAllOrganizations() {
            return new Promise<any>((resolve, reject) => {
                ApiService.setHeader();
                ApiService.get('organizations').then(({ data }) => {
                    resolve(data);
                }).catch(({ response }) => {
                    this.showError(response);
                    reject(response);
                });
            });
        },
        async getOrganization(data) {
            return new Promise<any>((resolve, reject) => {
                ApiService.setHeader();
                ApiService.get('organizations/' + data).then(({ data }) => {
                    resolve(data);
                }).catch(({ response }) => {
                    this.showError(response);
                    reject(response);
                });
            });
        },
        async createOrganization(data) {
            return new Promise<any>((resolve, reject) => {
                ApiService.setHeader();
                ApiService.post('organizations', data).then(({ data }) => {
                    resolve(data);
                }).catch(({ response }) => {
                    this.showError(response);
                    reject(response);
                });
            });
        },
        async updateOrganization(data) {
            return new Promise<any>((resolve, reject) => {
                ApiService.setHeader();
                ApiService.put('organizations/' + data.id, data.item).then(({ data }) => {
                    resolve(data);
                }).catch(({ response }) => {
                    this.showError(response);
                    reject(response);
                });
            });
        },
        async deleteOrganization(data) {
            return new Promise<any>((resolve, reject) => {
                ApiService.setHeader();
                ApiService.delete('organizations/' + data).then(({ data }) => {
                    resolve(data);
                }).catch(({ response }) => {
                    this.showError(response);
                    reject(response);
                });
            });
        },
        // Reports
        async getPeriodSummaryReport(data) {
            return new Promise<any>((resolve, reject) => {
                ApiService.setHeader();
                ApiService.post('reports/period-summary', data).then(({ data }) => {
                    resolve(data);
                }).catch(({ response }) => {
                    this.showError(response);
                    reject(response);
                });
            });
        },
        async getManagersSummary(data) {
            return new Promise<any>((resolve, reject) => {
                ApiService.setHeader();
                ApiService.post('reports/managers', data).then(({ data }) => {
                    resolve(data);
                }).catch(({ response }) => {
                    this.showError(response);
                    reject(response);
                });
            });
        },
        async getDriversSummary(data) {
            return new Promise<any>((resolve, reject) => {
                ApiService.setHeader();
                ApiService.post('reports/drivers', data).then(({ data }) => {
                    resolve(data);
                }).catch(({ response }) => {
                    this.showError(response);
                    reject(response);
                });
            });
        },
        async getVehicleHistory(data) {
            return new Promise<any>((resolve, reject) => {
                ApiService.setHeader();
                ApiService.post('history/vehicle', data).then(({ data }) => {
                    resolve(data);
                }).catch(({ response }) => {
                    this.showError(response);
                    reject(response);
                });
            });
        },
        // Tasks
        async getAllVehiclesTasks(data: AxiosRequestConfig | undefined = undefined) {
            return new Promise<any>((resolve, reject) => {
                ApiService.setHeader();
                ApiService.get('vehicles/all/tasks', data).then(({ data }) => {
                    resolve(data);
                }).catch(({ response }) => {
                    this.showError(response);
                    reject(response);
                });
            });
        },
        async getVehicleTasks(data) {
            return new Promise<any>((resolve, reject) => {
                ApiService.setHeader();
                ApiService.get(`vehicles/${data.id}/tasks`).then(({ data }) => {
                    resolve(data);
                }).catch(({ response }) => {
                    this.showError(response);
                    reject(response);
                });
            });
        },
        async updateAllTasks(data) {
            return new Promise<any>((resolve, reject) => {
                ApiService.setHeader();
                ApiService.post(`vehicles/${data.vehicle_id}/tasks/refresh`).then(({ data }) => {
                    resolve(data);
                }).catch(({ response }) => {
                    this.showError(response);
                    reject(response);
                });
            });
        },
        async finishTask(data) {
            return new Promise<any>((resolve, reject) => {
                ApiService.setHeader();
                ApiService.post(`vehicles/${data.vehicle_id}/tasks/finish/${data.task_id}`).then(({ data }) => {
                    resolve(data);
                }).catch(({ response }) => {
                    this.showError(response);
                    reject(response);
                });
            });
        },
        async deleteTask(data) {
            return new Promise<any>((resolve, reject) => {
                ApiService.setHeader();
                ApiService.post(`vehicles/${data.vehicle_id}/tasks/delete/${data.task_id}`).then(({ data }) => {
                    resolve(data);
                }).catch(({ response }) => {
                    this.showError(response);
                    reject(response);
                });
            });
        },
        async planTask(data) {
            return new Promise<any>((resolve, reject) => {
                ApiService.setHeader();
                ApiService.post(`vehicles/${data.vehicle_id}/tasks/plan`, data.task).then(({ data }) => {
                    resolve(data);
                }).catch(({ response }) => {
                    this.showError(response);
                    reject(response);
                });
            });
        },
        async createTask(vehicle_id, data) {
            return new Promise<any>((resolve, reject) => {
                ApiService.setHeader();
                ApiService.post(`vehicles/${vehicle_id}/tasks/create`, data).then(({ data }) => {
                    resolve(data);
                }).catch(({ response }) => {
                    this.showError(response);
                    reject(response);
                });
            });
        },
        async saveTask(vehicle_id, data) {
            return new Promise<any>((resolve, reject) => {
                ApiService.setHeader();
                ApiService.post(`vehicles/${vehicle_id}/tasks/update`, data).then(({ data }) => {
                    resolve(data);
                }).catch(({ response }) => {
                    this.showError(response);
                    reject(response);
                });
            });
        },
        async reorderTasks(vehicle_id, data) {
            return new Promise<any>((resolve, reject) => {
                ApiService.setHeader();
                ApiService.post(`vehicles/${vehicle_id}/tasks/reorder`, data).then(({ data }) => {
                    resolve(data);
                }).catch(({ response }) => {
                    this.showError(response);
                    reject(response);
                });
            });
        },
        // MARK: - Users
        async login(data) {
            return new Promise<any>((resolve, reject) => {
                ApiService.post("login", data).then(({data}) => {
                    resolve(data);
                }).catch(({ response }) => {
                    this.showError(response);
                    reject(response);
                });
            });
        },
        async switchOrganization(organization: Organization) {
            const payload = {
                organization_id: organization.id
            } as any;

            return new Promise<any>((resolve, reject) => {
                ApiService.post("switch-organization", payload).then(({data}) => {
                    resolve(data);
                }).catch(({ response }) => {
                    this.showError(response);
                    reject(response);
                });
            });
        },
        async getAllUsers() {
            return new Promise<any>((resolve, reject) => {
                ApiService.setHeader();
                ApiService.get('users').then(({ data }) => {
                    resolve(data);
                }).catch(({ response }) => {
                    this.showError(response);
                    reject(response);
                });
            });
        },
        async getUser(data) {
            return new Promise<any>((resolve, reject) => {
                ApiService.setHeader();
                ApiService.get('users/' + data).then(({ data }) => {
                    resolve(data);
                }).catch(({ response }) => {
                    this.showError(response);
                    reject(response);
                });
            });
        },
        async getCurrentUser() {
            return new Promise<any>((resolve, reject) => {
                ApiService.setHeader();
                ApiService.get('users/current').then(({ data }) => {
                    resolve(data);
                }).catch(({ response }) => {
                    this.showError(response);
                    reject(response);
                });
            });
        },
        async getCurrentUsersOrganizations(): Promise<Array<Organization>> {
            return new Promise<any>((resolve, reject) => {
                ApiService.setHeader();
                ApiService.get('users/current/organizations').then(({ data }) => {
                    resolve(data);
                }).catch(({ response }) => {
                    this.showError(response);
                    reject(response);
                });
            });
        },
        async createUser(data) {
            return new Promise<any>((resolve, reject) => {
                ApiService.setHeader();
                ApiService.post('users', data).then(({ data }) => {
                    resolve(data);
                }).catch(({ response }) => {
                    this.showError(response);
                    reject(response);
                });
            });
        },
        async updateUser(data) {
            return new Promise<any>((resolve, reject) => {
                ApiService.setHeader();
                ApiService.put('users/' + data.id, data.user).then(({ data }) => {
                    resolve(data);
                }).catch(({ response }) => {
                    this.showError(response);
                    reject(response);
                });
            });
        },
        async deleteUser(data) {
            return new Promise<any>((resolve, reject) => {
                ApiService.setHeader();
                ApiService.delete('users/' + data).then(({ data }) => {
                    resolve(data);
                }).catch(({ response }) => {
                    this.showError(response);
                    reject(response);
                });
            });
        },
        // MARK: - Vehicles
        async getAllVehicles() {
            return new Promise<any>((resolve, reject) => {
                ApiService.setHeader();
                ApiService.get('vehicles').then(({ data }) => {
                    resolve(data);
                }).catch(({ response }) => {
                    this.showError(response);
                    reject(response);
                });
            });
        },
        async getAvailableVehicleIdentifiers() {
            return new Promise<any>((resolve, reject) => {
                ApiService.setHeader();
                ApiService.get('identities').then(({ data }) => {
                    resolve(data);
                }).catch(({ response }) => {
                    this.showError(response);
                    reject(response);
                });
            });
        },
        async getVehicle(data) {
            return new Promise<any>((resolve, reject) => {
                ApiService.setHeader();
                ApiService.get('vehicles/' + data.vehicle_id).then(({ data }) => {
                    resolve(data);
                }).catch(({ response }) => {
                    this.showError(response);
                    reject(response);
                });
            });
        },
        async createVehicle(data) {
            return new Promise<any>((resolve, reject) => {
                ApiService.setHeader();
                ApiService.post('vehicles', data).then(({ data }) => {
                    resolve(data);
                }).catch(({ response }) => {
                    this.showError(response);
                    reject(response);
                });
            });
        },
        async updateVehicle(data) {
            return new Promise<any>((resolve, reject) => {
                ApiService.setHeader();
                ApiService.put('vehicles/' + data.vehicle_id, data.item).then(({ data }) => {
                    resolve(data);
                }).catch(({ response }) => {
                    this.showError(response);
                    reject(response);
                });
            });
        },
        async deleteVehicle(data) {
            return new Promise<any>((resolve, reject) => {
                ApiService.setHeader();
                ApiService.delete('vehicles/' + data).then(({ data }) => {
                    resolve(data);
                }).catch(({ response }) => {
                    this.showError(response);
                    reject(response);
                });
            });
        },
        async getUserVehicles() {
            return new Promise<any>((resolve, reject) => {
                ApiService.setHeader();
                ApiService.get('vehicles/all/status').then(({ data }) => {
                    resolve(data);
                }).catch(({ response }) => {
                    this.showError(response);
                    reject(response);
                });
            });
        },
        async getUserVehiclesHistory() {
            return new Promise<any>((resolve, reject) => {
                ApiService.setHeader();
                ApiService.get('vehicles/all/history').then(({ data }) => {
                    resolve(data);
                }).catch(({ response }) => {
                    this.showError(response);
                    reject(response);
                });
            });
        },
        async getUserVehicleTelemetryParams(data) {
            return new Promise<any>((resolve, reject) => {
                ApiService.setHeader();
                ApiService.get(`vehicles/${data.vehicle_id}/telemetry`).then(({ data }) => {
                    resolve(data);
                }).catch(({ response }) => {
                    this.showError(response);
                    reject(response);
                });
            });
        },
        async getUserVehiclesTacho(data: AxiosRequestConfig | undefined = undefined) {
            return new Promise<any>((resolve, reject) => {
                ApiService.setHeader();
                ApiService.get('vehicles/all/tacho').then(({ data }) => {
                    resolve(data);
                }).catch(({ response }) => {
                    this.showError(response);
                    reject(response);
                });
            });
        },
        async getNotifications(data: AxiosRequestConfig | undefined = undefined) {
            return new Promise<any>((resolve, reject) => {
                ApiService.setHeader();
                ApiService.get('notifications/all').then(({ data }) => {
                    resolve(data);
                }).catch(({ response }) => {
                    this.showError(response);
                    reject(response);
                })
            });
        },
        async acknowledgeNotification(data) {
            return new Promise<any>((resolve, reject) => {
                ApiService.setHeader();
                ApiService.post(`notifications/${data.notification_id}/acknowledge`).then(({ data }) => {
                    resolve(data);
                }).catch(({ response }) => {
                    this.showError(response);
                    reject(response);
                });
            });
        },
        async uploadFile(file, tags) {
            return new Promise<any>((resolve, reject) => {
                const formData = new FormData();
                formData.append('file', file);

                if (tags) {
                    if (Array.isArray(tags)) {
                        for (let i = 0; i < tags.length; i++) {
                            formData.append('tags[]', tags[i]);
                        }
                    } else {
                        formData.append('tags[]', tags);
                    }
                }

                ApiService.setHeader();
                ApiService.post('files/upload', (formData as any)).then(({ data }) => {
                    resolve(data);
                }).catch(({ response }) => {
                    this.showError(response);
                    reject(response);
                });
            });
        },
        async getDownloadUrl(key: string) {
            return new Promise<any>((resolve, reject) => {
                ApiService.setHeader();
                ApiService.post('files/url', ({ key: key } as any)).then(({ data }) => {
                    resolve(data);
                }).catch(({ response }) => {
                    this.showError(response);
                    reject(response);
                })
            });
        },
        async getTachographFileDownloadUrl(key: string) {
            return new Promise<any>((resolve, reject) => {
                ApiService.setHeader();
                ApiService.post('tachograph/files/url', ({ key: key } as any)).then(({ data }) => {
                    resolve(data);
                }).catch(({ response }) => {
                    this.showError(response);
                    reject(response);
                })
            });
        },
        async getAllChats() {
            return new Promise<any>((resolve, reject) => {
                ApiService.setHeader();
                ApiService.get('chat/list').then(({ data }) => {
                    resolve(data);
                }).catch(({ response }) => {
                    this.showError(response);
                    reject(response);
                });
            });
        },
        async getChatMessages(chatId: string, offset: number) {
            const payload = {
                chat_id: chatId,
                offset: offset
            };

            return new Promise<any>((resolve, reject) => {
                ApiService.setHeader();
                ApiService.get('chat/messages', { params: payload }).then(({ data }) => {
                    resolve(data);
                }).catch(({ response }) => {
                    this.showError(response);
                    reject(response);
                });
            }); 
        },
        async getChatMessagesByIds(chatId: string, messageIds: Array<string>) {
            const payload = {
                chat_id: chatId,
                message_ids: messageIds
            };

            return new Promise<any>((resolve, reject) => {
                ApiService.setHeader();
                ApiService.get('chat/messages/some', { params: payload }).then(({ data }) => {
                    resolve(data);
                }).catch(({ response }) => {
                    this.showError(response);
                    reject(response);
                });
            });
        },
        async sendChatMessage(chatId: string, message: string | undefined, file: any | undefined, fast_chat_question: any | undefined, fast_chat_response: any | undefined, original_message: any | undefined) {
            const payload = {
                chat_id: chatId,
                message: message,
                file: file,
                fast_chat_question: fast_chat_question,
                fast_chat_response: fast_chat_response,
                original_message: original_message
            };

            return new Promise<any>((resolve, reject) => {
                ApiService.setHeader();
                ApiService.post('chat/messages', (payload as any)).then(({ data }) => {
                    resolve(data);
                }).catch(({ response }) => {
                    this.showError(response);
                    reject(response);
                });
            }); 
        },
        async markChatMessagesAsSeen(chatId: string, messageIds: Array<string>) {
            const payload = {
                chat_id: chatId,
                message_ids: messageIds
            };

            return new Promise<any>((resolve, reject) => {
                ApiService.setHeader();
                ApiService.post('chat/seen', (payload as any)).then(({ data }) => {
                    resolve(data);
                }).catch(({ response }) => {
                    this.showError(response);
                    reject(response);
                });
            }); 
        },
        async deleteFastChatQuestion(id: number) {
            return new Promise<any>((resolve, reject) => {
                ApiService.setHeader();
                ApiService.delete(`fast-chat-questions/${id}`).then(({ data }) => {
                    resolve(data);
                }).catch(({ response }) => {
                    this.showError(response);
                    reject(response);
                });
            }); 
        },
        async getAllFastChatQuestions() {
            return new Promise<any>((resolve, reject) => {
                ApiService.setHeader();
                ApiService.get('fast-chat-questions').then(({ data }) => {
                    resolve(data);
                }).catch(({ response }) => {
                    this.showError(response);
                    reject(response);
                });
            }); 
        },
        async getFastChatQuestion(id: number) {
            return new Promise<any>((resolve, reject) => {
                ApiService.setHeader();
                ApiService.get(`fast-chat-questions/${id}`).then(({ data }) => {
                    resolve(data);
                }).catch(({ response }) => {
                    this.showError(response);
                    reject(response);
                });
            }); 
        },
        async createFastChatQuestion(message) {
            return new Promise<any>((resolve, reject) => {
                ApiService.setHeader();
                ApiService.post('fast-chat-questions', message).then(({ data }) => {
                    resolve(data);
                }).catch(({ response }) => {
                    this.showError(response);
                    reject(response);
                });
            }); 
        },
        async updateFastChatResponse(payload) {
            return new Promise<any>((resolve, reject) => {
                ApiService.setHeader();
                ApiService.put('fast-chat-questions/response', payload).then(({ data }) => {
                    resolve(data);
                }).catch(({ response }) => {
                    this.showError(response);
                    reject(response);
                });
            });
        },
        async createFastChatResponse(payload) {
            return new Promise<any>((resolve, reject) => {
                ApiService.setHeader();
                ApiService.post('fast-chat-questions/response', payload).then(({ data }) => {
                    resolve(data);
                }).catch(({ response }) => {
                    this.showError(response);
                    reject(response);
                });
            });
        },
        async deleteFastChatResponse(id) {
            return new Promise<any>((resolve, reject) => {
                ApiService.setHeader();
                ApiService.delete(`fast-chat-questions/response/${id}`).then(({ data }) => {
                    resolve(data);
                }).catch(({ response }) => {
                    this.showError(response);
                    reject(response);
                });
            });
        },
        async updateFastChatQuestion(payload) {
            return new Promise<any>((resolve, reject) => {
                ApiService.setHeader();
                ApiService.put('fast-chat-questions', payload).then(({ data }) => {
                    resolve(data);
                }).catch(({ response }) => {
                    this.showError(response);
                    reject(response);
                });
            });
        },
        async getAllFastChatMessages() {
            return new Promise<any>((resolve, reject) => {
                ApiService.setHeader();
                ApiService.get('fast-chat-messages').then(({ data }) => {
                    resolve(data);
                }).catch(({ response }) => {
                    this.showError(response);
                    reject(response);
                });
            }); 
        },
        async getFastChatMessage(id: number) {
            return new Promise<any>((resolve, reject) => {
                ApiService.setHeader();
                ApiService.get(`fast-chat-messages/${id}`).then(({ data }) => {
                    resolve(data);
                }).catch(({ response }) => {
                    this.showError(response);
                    reject(response);
                });
            }); 
        },
        async createFastChatMessage(message) {
            return new Promise<any>((resolve, reject) => {
                ApiService.setHeader();
                ApiService.post('fast-chat-messages', message).then(({ data }) => {
                    resolve(data);
                }).catch(({ response }) => {
                    this.showError(response);
                    reject(response);
                });
            }); 
        },
        async updateFastChatMessage(payload) {
            return new Promise<any>((resolve, reject) => {
                ApiService.setHeader();
                ApiService.put('fast-chat-messages', payload).then(({ data }) => {
                    resolve(data);
                }).catch(({ response }) => {
                    this.showError(response);
                    reject(response);
                });
            });
        },
        async deleteFastChatMessage(id: number) {
            return new Promise<any>((resolve, reject) => {
                ApiService.setHeader();
                ApiService.delete(`fast-chat-messages/${id}`).then(({ data }) => {
                    resolve(data);
                }).catch(({ response }) => {
                    this.showError(response);
                    reject(response);
                });
            }); 
        },
        async filterTachographFiles(start_date: Date, end_date: Date, vehicle_ids: Array<string> | undefined, driver_name: string | undefined) {
            return new Promise<any>((resolve, reject) => {
                const start_timestamp = start_date.getTime();
                const end_timestamp = end_date.getTime();

                const payload = {
                    start_timestamp: start_timestamp,
                    end_timestamp: end_timestamp,
                    vehicle_ids: vehicle_ids,
                    driver_name: driver_name
                }

                ApiService.setHeader();
                ApiService.post(`tachograph/files/filter`, (payload as any)).then(({ data }) => {
                    resolve(data);
                }).catch(({ response }) => {
                    this.showError(response);
                    reject(response);
                });
            });
        },
        async getTachoCardStatus() {
            return new Promise<any>((resolve, reject) => {
                ApiService.setHeader();
                ApiService.get(`tachograph/files/card-status`).then(({ data }) => {
                    resolve(data);
                }).catch(({ response }) => {
                    this.showError(response);
                    reject(response);
                });
            });
        },
        async getViaPointsTemplates() {
            return new Promise<any>((resolve, reject) => {
                ApiService.setHeader();
                ApiService.get(`via-points-templates`).then(({ data }) => {
                    resolve(data);
                }).catch(({ response }) => {
                    this.showError(response);
                    reject(response);
                });
            });
        },
        async createViaPointsTemplate(title: string, via_points: Array<any>) {
            const template = {
                title: title,
                via_points: via_points
            };

            return new Promise<any>((resolve, reject) => {
                ApiService.setHeader();
                ApiService.post(`via-points-templates`, (template as any)).then(({ data }) => {
                    resolve(data);
                }).catch(({ response }) => {
                    this.showError(response);
                    reject(response);
                });
            });
        },
        async deleteViaPointsTemplate(id: number) {
            return new Promise<any>((resolve, reject) => {
                ApiService.setHeader();
                ApiService.delete(`via-points-templates/${id}`).then(({ data }) => {
                    resolve(data);
                }).catch(({ response }) => {
                    this.showError(response);
                    reject(response);
                });
            });
        },
        async calculateRoute(payload: VRouteRequest): Promise<VRouteResposne> {
            return new Promise<any>((resolve, reject) => {
                ApiService.setHeader();
                ApiService.post('routes/calculate', payload).then(({ data }) => {
                    resolve(data);
                }).catch(({ response }) => {
                    this.showError(response);
                    reject(response);
                });
            })
        },
        async getAllRoles(): Promise<Array<Role>> {
            return new Promise<Array<Role>>((resolve, reject) => {
                ApiService.setHeader();
                ApiService.get('v1/organization/settings/roles/all').then(( { data }) => {
                    resolve(data);
                }).catch(({ response }) => {
                    this.showError(response);
                    reject(response);
                });
            })
        },
        async getAllPermissions(): Promise<Array<string>> {
            return new Promise<Array<string>>((resolve, reject) => {
                ApiService.setHeader();
                ApiService.get('v1/settings/permissions/all').then(( { data }) => {
                    resolve(data);
                }).catch(({ response }) => {
                    this.showError(response);
                    reject(response);
                });
            })
        }
    }
});