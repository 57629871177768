import { defineStore } from 'pinia'

const ID_MUTE_NOTIFICATION_SOUNDS = "mute_notification_sounds" as string;

interface State {
    muteNotificationSounds: boolean | undefined,
}

export const useUserSettingsStore = defineStore('UserSettingsStore', {
    state: (): State => ({
        muteNotificationSounds: window.localStorage.getItem(ID_MUTE_NOTIFICATION_SOUNDS) == 'true',
    }),
    actions: {
        // Private actions
        setMuteNotificationSounds(value: boolean) {
            if (value) {
                window.localStorage.setItem(ID_MUTE_NOTIFICATION_SOUNDS, 'true');
            } else {
                window.localStorage.removeItem(ID_MUTE_NOTIFICATION_SOUNDS);
            }

            this.muteNotificationSounds = value;
        },
        reset() {
            window.localStorage.removeItem(ID_MUTE_NOTIFICATION_SOUNDS);
            this.$reset();
        }
    }
});