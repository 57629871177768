import { createI18n } from "vue-i18n";

const messages = {
  en: {
    map: "Map",
    account_settings: "Account settings",
    language: "Language",
    english: "English",
    lithuanian: "Lithuanian",
    logout: "Sign Out",
    please_wait: "Please wait...",
    save: "Save",
    error: "Error",
    actions: "Actions",
    discard: "Discard",
    vehicles: "Vehicles",
    country: "Country",
    satellite: "Satellite",
    traffic_jams: "Traffic jams",
    traffic_conditions: "Traffic conditions", 
    ignition: "Ignition",
    ignition_on: "On",
    ignition_off: "Off",
    speed: "Speed",
    rpm: "RPM",
    odo: "ODO",
    trip: "Trip",
    battery: "Battery",
    all_parameters: "All parameters",
    add_new_task: "Add new task",
    origin: "Orgin",
    destination: "Destination",
    company_name: "Company name",
    plan: "Plan",
    loading: "Loading...",
    no_locations_found: "Can't find any locations",
    summaries: "Summaries",
    period_summary: "Period summary",
    time_period: "Period",
    vehicle: "Vehicle",
    gps_distance: 'GPS (km)',
    odometer_distance: 'ODO (km)',
    fuel_consumed: 'Fuel consumed (L)',
    gps_fuel_consumption: 'GPS (l/100km)',
    odometer_fuel_consumption: 'ODO (l/100km)',
    start_odometer: 'From (km)',
    end_odometer: 'To (km)',
    total: 'Total',
    select_vehicles: 'Select vehicles',
    no_vehicles_found: "Can't find any vehicles",
    generate: "Generate",
    error_field_is_required: "This field is required",
    error_start_date_in_future: "Start date can't be in the future",
    error_start_date_later_than_end: "End date must be later than start date",
    settings: "Settings",
    create_new_user: "Create new user",
    users: "Users",
    user_data: "User data",
    name: "Name",
    email: "Email",
    organization: "Organization",
    password: "Password",
    password_confirmation: "Password confirmation",
    permissions: "Permissions",
    assigned_vehicles: "Assigned vehicles",
    create_new_organization: "Create new organization",
    organizations: "Organizations",
    organization_data: "Organization data",
    title: "Title",
    create_new_vehicle: "Create new vehicle",
    vehicle_data: "Vehicle data",
    identifier: "Identifier",
    tank_capacity: "Tank capacity (L)",
    axles: "Axles",
    trailer_axles: "Trailer axles",
    length_in_cm: "Length (cm)",
    width_in_cm: "Width (cm)",
    height_in_cm: "Height (cm)",
    weight_in_kg: "Weight (kg)",
    type: "Type",
    distance: "Distance",
    duration: "Duration",
    fuel_consumption: "Fuel consumption",
    start_date: "Started at",
    end_date: "Finished at",
    stop_duration: "Stop duration",
    navigation_unit: "Navigation unit",
    navigation_units: "Navigation units",
    navigation_units_list_subtitle: "Vestigo application contains {count} navigation units",
    create_navigation_unit: "Create navigation unit",
    serial: "Serial number",
    vehicle_title: "Vehicle",
    app_versions: "App versions",
    app_version: "App version",
    version_number: "Version number",
    description: "Description",
    url: "Url",
    create_app_version: "Create app version",
    app_versions_list_subtitle: "Vestigo application contains {count} app versions",
    cancel: "Cancel",
    update_task: "Update",
    reorder_tasks: "Reorder",
    add_task_above: "Add task above",
    add_task_below: "Add task below",
    delete_task: "Delete task",
    edit_task: "Edit task",
    select_vehicle_placeholder: "Select vehicle",
    no_vehicles_text: "Can't find any vehicles",
    fuel_consumption_unit: 'l/100km',
    daily_odo: "Daily ODO",
    daily_consumption: "Fuel consumption",
    daily_average_speed: "Average speed",
    'tacho_state_-1': "Rest (without card)",
    tacho_state_0: "Rest",
    tacho_state_1: "Driver available",
    tacho_state_2: "Work",
    tacho_state_3: "Drive",
    tacho_state_6: "Error",
    tacho_state_7: "Not available",
    tacho_state_missing: "Missing data",
    from: "From",
    to: "To",
    odometer_from: "ODO (km) from",
    odometer_to: "ODO (km) to",
    tacho_shift_time_left: "Time left to work",
    ends_at: "Ends at",
    time_left: "Time left",
    rest_type: "Rest type",
    rest_duration: "Rest duration",
    tacho_rest_title_daily: "Daily rest",
    tacho_rest_title_weekly: "Weekly rest",
    driving_time: "Driving time",
    tacho_shift_title: "Work day",
    tacho_chart: "Chart",
    tacho_details: "Details",
    time_left_short: "Left",
    tacho_details_current_activity: "Current activity",
    tacho_details_activity: "Activity",
    tacho_details_activity_start: "Activity started at",
    tacho_details_activity_duration: "Activity duration (till now)",
    tacho_details_rest: "Rest",
    tacho_details_last_rest_duration: "Last rest duration",
    tacho_details_last_weekly_rest_duration: "Last weekly rest duration",
    tacho_details_shortened_daily_rest_times_count: "Shortened (9h) rest days count this week",
    tacho_details_daily_rest_start: "Start daily rest at",
    tacho_details_weekly_rest_start: "Start weekly rest at",
    tacho_details_driving_today: "Today driving",
    tacho_details_driving_weekly: "Weekly driving",
    tacho_details_shift_start: "Shift started at",
    tacho_details_driving_period_start: "Driving period started at",
    tacho_details_driving_period_driving_time: "Current period driving duration",
    tacho_details_driving_time_today: "Driving duration today",
    tacho_details_driving_time_this_week: "Driving duration this week",
    tacho_details_driving_time_previous_week: "Driving duration last week",
    tacho_details_driving_time_two_weeks: "Driving duration in two weeks",
    tacho_details_extended_driving_times_count: "Extended (10h) driving days this week",
    tacho_details_work: "Work",
    tacho_details_this_week: "This week",
    tacho_details_today: "Today",
    tacho_details_available: "Available",
    monday: 'Monday',
    tuesday: 'Tuesday',
    wednesday: 'Wednesday',
    thursday: 'Thursday',
    friday: 'Friday',
    saturday: 'Saturday',
    sunday: 'Sunday',
    january: 'January',
    february: 'February',
    march: 'March',
    april: 'April',
    may: 'May',
    june: 'June',
    july: 'July',
    august: 'August',
    september: 'September',
    october: 'October',
    november: 'November',
    december: 'December',
    shifts: 'Work days',
    tachograph: 'Tachograph',
    countries: 'Countries',
    copy_coordinates: "Copy coordinates",
    open_street_view: 'Open truck location in "Street View"',
    add_via_marker: 'Add via point',
    sim: 'SIM',
    current_app_version: 'Current version',
    assigned_app_version: 'Assigned version',
    tacho_drive_time_left_until_break: "Drive time left until break",
    tacho_drive_time_left_today: "Drive time left today",
    violations: "Violations",
    tacho_violation_prolonged_driving_without_break: "Exceeded maximum (4h 30min) driving duration without 45min break",
    tacho_violation_missed_daily_rest_start_deadline: "Missed daily rest deadline",
    tacho_violation_missed_weekly_rest_start_deadline: "Missed weekly rest deadline",
    tacho_violation_shortened_daily_rest_overuse: "Exceeded maximum number (3) of shortened (9h) daily rests between two weekly rests",
    tacho_violation_too_short_weekly_rest: "Weekly rest is too short",
    tacho_violation_too_long_driving_period: "Exceeded maximum total driving time",
    tacho_violation_too_long_total_working_time: "Exceeded maximum total working time",
    tacho_violation_extended_driving_time_overuse: "Exceeded maximum (2) extended (10h) driving times per week",
    tacho_violation_exceeded_maximum_one_week_driving_time: "Exceeded maximum (56h) weekly driving time",
    tacho_violation_exceeded_maximum_two_weeks_driving_time: "Exceeded maximum (90h) two-week driving time",
    tacho_violation_too_short_daily_rest: "Daily rest is too short",
    task_info_about_creation_template: "Created at {created_at} by {name}",
    task_planner: "Task planner",
    address: "Address",
    info: "Info",
    additional_info: "Additional info",
    trip_number: "Trip number",
    arrival_time: "Arrival time",
    comment: "Comment",
    cargo: "Cargo",
    task_info_about_trip_number: "Trip: {trip_number}",
    task_info_about_task_id: "Task number: {id}",
    task_info_about_arrival_time: "Arrival time: {arrival_time}",
    task_info_about_comment: "{comment}",
    task_photo_verification: "Photo verification",
    task_photo_verification_side: "Trailer side",
    task_photo_verification_back: "Trailer back",
    cargo_new: "New cargo",
    cargo_edit: "Edit cargo",
    cargo_view: "View cargo",
    cargo_identifier: "Name",
    cargo_reference: "Reference",
    cargo_amount: "Amount",
    cargo_amount_units: "pcs.",
    cargo_size: "Size",
    cargo_size_units: "LDM",
    cargo_unload_address: "Unload address",
    cargo_comment: "Comment",
    cargo_document_requests: "Documents",
    cargo_document_requests_cmr: "CMR",
    cargo_document_requests_pll: "PLL Note",
    cargo_document_requests_delivery_note: "Delivery Note",
    cargo_add: "Add cargo",
    task_type_disabled_due_to_selected_cargo: "Can't change task type while it has cargo assigned",
    cargo_delete_disabled_due_to_loading: "Can't delete cargo that is already assigned to another task",
    load: "Load",
    unload: "Unload",
    edit_planned_cargo: "Edit planned cargo",
    edit: "Edit",
    ldm: "LDM",
    close: "Close",
    cargo_does_not_fit_trailer: "Cargo doesn't fit in the trailer",
    unload_address_copied: "Unload address has been copied",
    view_planned_cargo: "Planned cargo",
    no_tacho_data_title: "No tacho data",
    no_tacho_data_subtitle: "We don't have data from any tachograph card at the moment",
    cargo_overload_title: "Overloaded by {size} LDM",
    cargo_underload_title: "Underloaded by {size} LDM",
    cargo_overload_delete_title: "Delete overload",
    cargo_overload_delete_message: "Are you sure you want to delete {size} LDM overload?",
    cargo_underload_delete_title: "Delete underload",
    cargo_underload_delete_message: "Are you sure you want to delete {size} LDM underload?",
    confirm: "Confirm",
    trailer_type: "Trailer type",
    vehicle_search_placeholder: "Search by license plate or driver",
    no_tasks: "There are no tasks",
    tacho_prolonged_drive_periods_description: "{value} out of the {limit} available prolonged driving periods (10H) used this week",
    tacho_shortened_daily_rest_periods_description: "{value} out of the {limit} available shortened (9H) daily rests used this week",
    tacho_weekly_rest_start_description: "Next weekly rest must start not later than {value}",
    tacho_weekly_drive_time_description: "Driving duration this week {value} out of {limit}. Weekly time left: {remaining}.",
    tacho_biweekly_drive_time_description: "Two weeks driving duration {value} out of {limit}. Two weeks driving time left {remaining}.",
    months: "January_February_March_April_May_June_July_August_September_October_November_December",
    weekdays: "Sunday_Monday_Tuesday_Wednsday_Thursday_Friday_Saturday",
    cargo_empty_list_message: "There is no cargo available",
    cruise_control_active: "Cruise control is active",
    battery_voltage_low: "Low battery voltage: {value}V",
    engine_temperature_high: "High engine temperature: {value}°",
    service_distance_low: "Service distance: {value}km",
    outside_temperature_low: "Low air temperature: {value}°",
    outside_temperature_high: "High air temperature: {value}°",
    tasks: "Tasks",
    updated_task_attribute_destination: "address",
    updated_task_attribute_via: "via points",
    updated_task_attribute_type: "type",
    updated_task_attribute_trip_number: "trip number",
    updated_task_attribute_arrival_time: "FIX time",
    updated_task_attribute_comment: "comment",
    updated_task_attribute_photo_request_side: "photo verification",
    updated_task_attribute_photo_request_back: "photo verification",
    updated_task_attribute_attachment_id: "attachment",
    updated_task_attribute_cargo: "cargo",
    updated_task_attribute_ignore_traffic: "traffic conditions",
    updated_task_attribute_ignore_truck_restrictions: "truck restrictions",
    notification_task_deleted: "{user_name} deleted {task_type_text} task {destination}.",
    notification_current_task_changed: "{user_name} changed current task to {destination}.",
    notification_task_updated: "{user_name} updated {task_type_text} task {destination}.",
    notification_task_updated_with_attributes_list: "{user_name} updated {updated_attributes_t} for {task_type_text} task {destination}.",
    notification_cargo_size_overload: "Loaded {size} LDM more than planned",
    notification_cargo_size_underload: "Loaded {size} LDM less than planned",
    notification_cargo_amount_overload: "Cargo was bigger than planned. Planned {expected_amount} pcs, loaded {actual_amount} pcs",
    notification_cargo_amount_underload: "Cargo was smaller than planned. Planned {expected_amount} pcs, loaded {actual_amount} pcs",
    notification_weight_axle_1_max: "Axle 1 load has exceed {limit}kg limit (value: {value}kg)",
    notification_weight_axle_1_min: "Axle 1 load has fallen below {limit}kg (value: {value}kg)",
    notification_weight_axle_2_max: "Axle 2 load has exceed {limit}kg limit (value: {value}kg)",
    notification_weight_axle_2_min: "Axle 2 load has fallen below {limit}kg (value: {value}kg)",
    notification_weight_axles_combined_max: "Combined axles load has exceed {limit}kg limit (value: {value}kg)",
    notification_weight_axles_combined_min: "Combined axles load has fallen below {limit}kg (value: {value}kg)",
    notification_weight_total_max: "Total weight has exceed {limit}kg limit (value: {value}kg)",
    notification_weight_total_min: "Total weight has fallen below {limit}kg (value: {value}kg)",
    notification_fuel_level_max: "Fuel level has exceed {limit}% limit (value: {value}%)",
    notification_fuel_level_min: "Fuel level has fallen below {limit}% limit (value: {value}%)",
    notification_adblue_level_max: "AdBlue level has exceed {limit}% limit (value: {value}%)",
    notification_adblue_level_min: "AdBlue level has fallen below {limit}% limit (value: {value}%)",
    notification_action_view: "View",
    notification_action_show: "View",
    notification_action_confirm: "Confirm",
    license_plate: "License plate",
    trailer_license_plate: "Trailer license plate",
    vehicle_type_truck: "Truck",
    vehicle_type_trailer: "Trailer",
    trailer_type_ref: "Reefer",
    trailer_type_tent: "Tent",
    trailer_type_box: "Box",
    trailer_type_mega: "Mega",
    trailer_type_train: "Train",
    trailer_type_tanker: "Tanker",
    trailer_type_platform: "Oversized",
    trailer_type_autotransporter: "Autotransporter",
    chat_role_driver: "Driver",
    chats_list: "Chat",
    today: "Today",
    yesterday: "Yesterday",
    sent: "Sent",
    role: "Role",
    login: "Login",
    seen: "Seen",
    not_seen: "Not seen",
    seen_by: "Seen by",
    seen_by_driver: "Seen by driver",
    not_seen_by_driver: "Not seen by driver",
    file: "File",
    add_file: "Add file",
    fast_chat_messages: "Fast messages",
    fast_chat_message: "Fast message",
    new_fast_chat_message: "New fast message",
    fast_chat_questions: "Fast questions",
    fast_chat_question: "Fast question",
    fast_chat_responses: "Responses",
    fast_chat_response: "Response",
    new_fast_chat_question: "New fast question",
    new_fast_chat_response: "New response",
    message_in_lt: "Message (LT)",
    message_in_en: "Message (EN)",
    message_in_ru: "Message (RU)",
    task_type: "Task type",
    fast_chat_intro_loading: "Driver is at loading",
    fast_chat_intro_unloading: "Driver is at unloading",
    fast_chat_intro_fuel: "Driver is at fuel station",
    fast_chat_intro_parking: "Driver is at parking",
    fast_chat_intro_trailer_change: "Driver is at trailer change",
    fast_chat_is_active: "Fast chat is active",
    filter_by_vehicles: "Filter by vehicles",
    filter_by_driver: "Filter by drivers",
    filter: "Filter",
    driver: "Driver",
    download_date: "Downloaded at",
    download_all_files: "Download all files",
    chart: "Chart",
    files: "Files",
    driver_file: "Driver file",
    vehicle_file: "Vehicle file",
    results: "Results",
    company_card_is_connected: "Company card is connected",
    company_card_is_disconnected: "Company card is disconnected",
    drivers: "Drivers",
    no_data: "No data",
    download: "Download",
    month_abbreviation: "mo.",
    task_type_loading: "Loading",
    task_type_unloading: "Unloading",
    task_type_parking: "Parking",
    task_type_fuel: "Fuel station",
    task_type_trailer_change: "Change trailer",
    task_type_customs: "Customs",
    task_type_ferry: "Ferry",
    task_type_maintenance: "Service",
    task_type_reloading: "Re-loading",
    task_type_train: "Train",
    task_type_wash: "Wash",
    task_finished_description_loading: "Loading task finished",
    task_finished_description_unloading: "Unloading task finished",
    task_finished_description_parking: "Parking task finished",
    task_finished_description_fuel: "Fuel station task finished",
    task_finished_description_trailer_change: "Trailer change task finished",
    task_finished_description_customs: "Customs task finished",
    task_finished_description_ferry: "Ferry task finished",
    task_finished_description_maintenance: "Service task finished",
    task_finished_description_train: "Train task finished",
    task_finished_description_wash: "Wash task finished",
    task_finished_description_reloading: "Re-loading task finished",
    chat_sender_system: "System, Vestigo",
    task_loaded_ldm_tooltip: "Loaded {loaded} LDM out of planned {planned} LDM",
    task_loaded_amount_tooltip: "Loaded {loaded} pcs. out of planned {planned} pcs.",
    cargo_info: "Cargo info",
    trailer_side_photos: "Trailer side photos",
    trailer_back_photos: "Trailer back photos",
    cmr_photos: "CMR photos",
    pll_note_photos: "PLL note photos",
    delivery_note_photos: "Delivery note photos",
    no_cargo_selected_modal_title: "No cargo selected",
    no_cargo_selected_modal_description: "Do you really want to continue without any cargo selected for this task?",
    delete_task_confirmation_modal_title: "Delete task",
    delete_task_confirmation_modal_description: "Are you sure you want to delete this task",
    no: "No",
    yes: "Yes",
    page_title_new_messages: "New messages",
    weekly_tacho_rest_tooltip: "Last weekly rest duration: {duration}",
    weekly_tacho_rest_tooltip_no_data: "No data about last weekly rest",
    have_new_messages: "New messages",
    have_messages_unread_by_driver: "Driver hasn't read all messages for longer than {time}",
    no_tacho_card_tooltip: "Tachograph has no card since: {date}",
    vehicles_count_tooltip: "No trucks | One truck | {count} trucks",
    loading_more_messages: "Loading more messages...",
    chat_end: "End of chat history",
    ignore_traffic_conditions: "Ignore traffic conditions",
    ignore_truck_restrictions: "Ignore truck restrictions",
    traffic_and_restrictions_disable_title: "Disable traffic and truck restrictions?",
    traffic_and_restrictions_disable_description: "Via points might negatively impact a route if it's in the area which would be avoided by navigation due to traffic conditions or truck restrictions otherwise. We advise to disable those for this task if you want to shape the route yourself.",
    traffic_and_restrictions_disable_confirm_title: "Yes, disable",
    traffic_and_restrictions_disable_cancel_title: "No, do not change these settings",
    delete_all_via_points: "Delete all",
    save_as_template: "Save as template",
    route_settings: "Route settings",
    save_via_points_as_template_title: "Save via points as template",
    via_points_template_title: "Template title",
    via_points_count: "Via points",
    via_points_templates: "Via points templates",
    apply: "Apply",
    delete: "Delete",
    applied: "Applied",
    no_via_points_templates: "There are no saved via points templates",
    task_via_points_count_tooltip: "Route has no via points | Route has 1 via point | Rout has {count} via points",
    task_disabled_traffic_tooltip: "Traffic conditions are disabled for this route",
    task_disabled_truck_restrictions_tooltip: "Truck restrictions are disabled for this route",
    created_by: "Created by",
    delete_via_points_template_confirmation_modal_title: "Delete via points template",
    delete_via_points_template_confirmation_modal_description: "Are you sure you want to delete this via points template? It will be deleted for the whole organization.",
    delete_cargo_confirmation_modal_title: "Delete cargo",
    delete_cargo_confirmation_modal_description: "Are you sure you want to delete this cargo?",
    allow_map_download: "Allow map download",
    tasks_unavailable_because_vehicle_has_no_nav_unit: "Tasks are unavailable because there is no navigation unit assigned to this vehicle",
    plan_route_from_location: "Plan route from here",
    plan_route_to_location: "Plan route to here",
    delete_marker: "Delete marker",
    close_local_route_planner: "Close route planner",
    collapse_local_route_planner: "Collapse route planner",
    expand_local_route_planner: "Expand route planner",
    local_route_planner: "Route planner",
    adress_search: "Search for address",
    all_messages: "All messages",
    speed_description: "Speed: {speed} km/h",
    speed_with_limit_description: "Speed: {speed} km/h\nSpeed limit: {speed_limit} km/h",
    notification_fast_chat_response: "Fast chat response",
    notification_fast_chat_message: "Fast chat message",
    pick_organization_you_want_to_login_to: "Pick organization you want to login to:",
    invalid_credentials: "Invalid email or password.",
    finish_task: "Finish task",
    finish_task_confirmation: "Are you sure you want to finish task {destination}? Photos and documents, if applicable, will be missing.",
    missing_photos: "Missing photos",
    task_finished_by: "Task finished by",
    vehicle_list_trailer_type_description: "Trailer type: {type}",
    vehicle_list_trailer_type_with_license_plate_description: "Trailer type: {type}\nLicense plate: {license_plate}",
    vehicle_list_tasks_count_description: "Tasks count: {count}",
    vehicle_list_current_task_description: "Current task: {type}\nRemaining distance: {distance}",
    vehicle_list_current_task_driving_duration_description: "Remaining driving time: {duration}\n- The driving time doesn't take into account the driver's work/rest schedule.",
    vehicle_list_fix_arrival_time_description: "Fixed arrival time: {time}",
    vehicle_list_fix_arrival_time_error_description: "- Truck might not arrive at fixed arrival time.",
    vehicle_list_tacho_driver_description: "Driver: {driver}",
    vehicle_list_tacho_weekly_driving_warning_description: "- Less than 18 hours of weekly or bi-weekly driving time left.",
    vehicle_list_tacho_weekly_driving_error_description: "- Less than 9 hours of weekly or bi-weekly driving time left.",
    vehicle_list_fuel_level_warning_or_error_description: "- Fuel level is below {limit}%.",
    vehicle_list_adblue_level_warning_or_error_description: "- AdBlue level is below {limit}%.",
    vehicle_list_arrived_at_loading_description: "Arrived at loading location for {time}.",
    vehicle_list_arrived_at_unloading_description: "Arrived at unloading location for {time}.",
    vehicle_list_open_chat_description: "Open chat",
    vehicle_list_status_offline: "Tracking device is offline",
    vehicle_list_status_active: "Task in progress",
    vehicle_list_status_driving: "Moving",
    vehicle_list_status_ignition: "Ignition is on",
    vehicle_list_status_inactive: "Not moving",
    tasks_list_total_distance_description: "Total distance of all tasks",
    tasks_list_total_duration_description: "Total driving duration of all tasks",
    tasks_list_total_tolls_description: "Total tolls of all tasks",
    tasks_list_fix_arrival_time_description: "Fixed arrival time: {time}",
    tasks_list_distance_description: "Distance: {distance}",
    tasks_list_duration_description: "Driving duration: {duration}\n- The driving time doesn't take into account the driver's work/rest schedule.",
    tasks_list_eta_description: "Approximate arrival time: {eta}\n- The driving time doesn't take into account the driver's work/rest schedule.",
    tasks_list_tolls_description: "Tolls: {tolls}",
    tasks_list_reorder_description: "Reorder",
    task_planner_type_title: "Type (required)",
    task_planner_destination_title: "Destination (required)",
    task_planner_invalid_task_description: "It is mandatory to select task type and destination",
    cargo_document_request_description: "When finishing the task driver will be asked to provide photos of selected documents",
    vehicle_details_country_description: "Current country: {country}",
    vehicle_details_tag_description: "Tag: {tag}",
    weight_axle_1_description: "First axle weight",
    weight_axles_combined_description: "Total truck's axles weight",
    weight_axles_2_description: "Second axle weight",
    weight_total_description: "Total weight\n- The value is calculated from the engine load and therefore may not be accurate.",
    open_chat: "Open chat",
    effectiveness_of_managers: "Effectiveness of managers",
    managers: "Managers",
    manager: "Manager",
    select_all: "Select all",
    deselect_all: "Deselect all",
    avg_problem_reaction_time: "Reaction to problems",
    time_without_task: "Time without tasks",
    avg_loading_time: "Avg. loading duration",
    tasks_with_problems: "Tasks with problems",
    avg_message_response_time: "Reaction to messages",
    avg_fast_chat_message_response_time: "Reaction to fast chat",
    avg_fuel_consumption: "Fuel consumption",
    avg_fuel_consumption_per_ton: "Fuel consumption per TON",
    total_driving_duration: "Total driving duration",
    total_mileage: "Total mileage",
    summary: "Report",
    select_managers: "Select managers",
    manager_summary: "Managers summary",
    effectiveness_of_drivers: "Effectiveness of drivers",
    select_drivers: "Select drivers",
    eco_driving: "ECO Driving",
    tachograph_faults: "Tachograph faults",
    route_deviation: "Route deviations",
    engine_idling_duration: "Engine idling duration",
    fix_time_tasks_problem: "Late arrivals to FIX tasks",
    driver_summary: "Drivers summary",
    history: "History",
    current_month: "Current month",
    previous_month: "Previous month",
    truck: "Truck",
    drive_interval: "Drive interval",
    drive: "Drive",
    stop: "Stop",
    status: "Status",
    no_history_report_subtitle: "Choose truck, period and press button \"GENERATE\" to generate truck's history data",
    coordinates: "Coordinates",
    history_start: "Start of history",
    history_end: "End of history",
    current_week: "Current week",
    previous_week: "Previous week",
    avg_problem_reaction_time_description: "<b>Reaction to problems time</b><br/>Time since the problem arises till the manager reacts to it.",
    time_without_task_description: "<b>Time without tasks</b><br/>Amount of time that manager's trucks spent without tasks.",
    avg_loading_time_description: "<b>Average loading duration</b><br/>The average amount of time manager's trucks spends at loading/unloading tasks from arrival till finishing the task.",
    avg_message_response_time_description: "<b>Reaction to messages time</b><br/>Average time of how much time it takes to read the chat message.",
    total_mileage_description: "<b>Total mileage</b><br/>Total mileage during the selected period of time.",
    engine_idling_duration_description: "<b>Engine idling</b><br/>Total duration of engine idling.",
    avg_fast_chat_message_response_time_description: "<b>Reaction to fast chat messages time</b><br/>Average time of how long it takes to answer the fast chat messages.",
    avg_fuel_consumption_description: "<b>Fuel consumption</b><br/>Fuel consumption per 100km.",
    avg_fuel_consumption_per_ton_description: "<b>Fuel consumption per ton</b><br/>This parameter indicates how much fuel was used to drive 1ton of weight for 100km.",
    total_driving_duration_description: "<b>Total driving duration</b><br/>Total driving duration during the selected period of time.",
    total_gps_distance: 'Total mileage (GPS)',
    total_odometer_distance: 'Total mileage (ODO)',
    total_fuel_consumed: 'Fuel consumed',
    total_gps_fuel_consumption: 'Fuel consumption (GPS)',
    total_odometer_fuel_consumption: 'Fuel consumption (ODO)',
    interval_start_odometer: 'Odometer start',
    interval_end_odometer: 'Odometer end',
    total_gps_distance_description: '<b>Total mileage (GPS)</b><br/>Total mileage based on GPS data.',
    total_odometer_distance_description: '<b>Total mileage (ODO)</b><br/>Total mileage based on odometer data.',
    total_fuel_consumed_description: '<b>Fuel consumed</b><br/>Amount of fuel consumed. (L)',
    total_gps_fuel_consumption_description: '<b>Fuel consumption (GPS)</b><br/>Average fuel consumption based on GPS data. (l/100km)',
    total_odometer_fuel_consumption_description: '<b>Fuel consumption (ODO)</b><br/>Average fuel consumption based on odometer data. (l/100km)',
    interval_start_odometer_description: '<b>Odometer start</b><br/>Odometer reading at the beginning of the interval.',
    interval_end_odometer_description: '<b>Odometer end</b><br/>Odometer readings at the end of the interval.',
    view_period_summary: "View period summary", 
    view_managers_report: "View manager report",
    view_drivers_report: "View driver report", 
    view_history: "View vehicle history", 
    view_tachograph_files: "View tachograph files",
    manage_system_settings: "Manage system settings",
    manage_organization_settings: "Manage company settings",
    manage_users: "Manage users",
    manage_tasks: "Manage tasks",
    view_trucks_map: "View truck map",
    create_via_points_templates: "Create via points templates",
    delete_via_points_templates: "Delete via points templates",
    use_chat: "Use chat",
    hidden_seen_status: "Hidden seen status",
    select_organization: "Select organization",
    reset_password: "Reset password",
    reset_password_description: "Password reset instructions will be sent to the user's email address.",
    api_error_11001: "Failed to calculate route",
    api_error_11002: "Truck not found",
    api_error_11003: "Chat not found",
    api_error_11004: "Message not found",
    api_error_11005: "Task not found",
    api_error_11006: "Trip not found",
    api_error_11007: "Invalid date interval",
    api_error_11008: "Interval can't be longer than 90 days",
    api_error_11009: "Fast chat question must have at least one response",
    api_error_11010: "Task is already started",
    api_error_11011: "Another task is already in progress",
    api_error_11012: "Access denied",
    mute_notifications: "Turn off sound notifications",
    unmute_notifications: "Turn on sound notifications"
  },
  lt: {
    map: "Žemėlapis",
    account_settings: "Profilio nustatymai",
    language: "Kalba",
    english: "Anglų",
    lithuanian: "Lietuvių",
    logout: "Atsijungti",
    please_wait: "Palaukite...",
    save: "Išsaugoti",
    error: "Klaida",
    actions: "Veiksmai",
    discard: "Atstatyti",
    vehicles: "Automobiliai",
    country: "Šalis",
    satellite: "Palydovas",
    traffic_jams: "Spūstys",
    traffic_conditions: "Eismo sąlygos", 
    ignition: "Degimas",
    ignition_on: "Įjungtas",
    ignition_off: "Išjungtas",
    speed: "Greitis",
    rpm: "RPM",
    odo: "ODO",
    trip: "Kelionė",
    battery: "Baterija",
    all_parameters: "Visi parametrai",
    add_new_task: "Pridėti naują užduotį",
    origin: "Pradžia",
    destination: "Pabaiga",
    company_name: "Įmonės pavadinimas",
    plan: "Planuoti",
    loading: "Kraunama...",
    no_locations_found: "Nepavyko rasti jokių vietovių",
    summaries: "Ataskaitos",
    period_summary: "Periodo ataskaita",
    time_period: "Periodas (nuo/iki)",
    vehicle: "Automobilis",
    gps_distance: 'GPS (km)',
    odometer_distance: 'ODO (km)',
    fuel_consumed: 'Sunaudota kuro (L)',
    gps_fuel_consumption: 'GPS (l/100km)',
    odometer_fuel_consumption: 'ODO (l/100km)',
    start_odometer: 'Nuo (km)',
    end_odometer: 'Iki (km)',
    total: 'Viso',
    select_vehicles: 'Pasirinkite automobilius',
    no_vehicles_found: 'Nepavyko rasti automobilių',
    generate: "Generuoti",
    error_field_is_required: "Šis laukas privalomas",
    error_start_date_in_future: "Pradžios data turi būti ankstesnė nei dabar",
    error_start_date_later_than_end: "Pabaigos data turi būti vėlesnė už pradžios datą",
    settings: "Nustatymai",
    create_new_user: "Sukurti naują vartotoją",
    users: "Vartotojai",
    user_data: "Vartotojo duomenys",
    name: "Vardas",
    email: "El. paštas",
    organization: "Organizacija",
    password: "Slaptažodis",
    password_confirmation: "Slaptažodžio patvirtinimas",
    permissions: "Teisės",
    assigned_vehicles: "Priskirti automobiliai",
    create_new_organization: "Sukurti naują organizaciją",
    organizations: "Organizacijos",
    organization_data: "Organizacijos duomenys",
    title: "Pavadinimas",
    create_new_vehicle: "Sukurti naują automobilį",
    vehicle_data: "Automobilio duomenys",
    identifier: "Identifikatorius",
    tank_capacity: "Bako talpa (L)",
    axles: "Ašys",
    trailer_axles: "Priekabos ašys",
    length_in_cm: "Ilgis (cm)",
    width_in_cm: "Plotis (cm)",
    height_in_cm: "Aukštis (cm)",
    weight_in_kg: "Svoris (kg)",
    type: "Tipas",
    distance: "Atstumas",
    duration: "Trukmė",
    fuel_consumption: "Kuro sąnaudos",
    start_date: "Pradžia",
    end_date: "Pabaiga",
    stop_duration: "Sustojimo laikas",
    navigation_unit: "Navigacijos įrenginys",
    navigation_units: "Navigacijos įrenginiai",
    navigation_units_list_subtitle: "Vestigo programoje yra {count} navigacijos įrenginiai",
    create_navigation_unit: "Naujas navigacijos įrenginys",
    serial: "Serijinis numeris",
    vehicle_title: "Automobilis",
    app_versions: "Programos versijos",
    app_version: "Programos versija",
    version_number: "Versija",
    description: "Aprašymas",
    url: "Nuoroda",
    create_app_version: "Nauja programos versija",
    app_versions_list_subtitle: "Vestigo programoje yra {count} programos versijos",
    cancel: "Atšaukti",
    update_task: "Atnaujinti",
    reorder_tasks: "Keisti eiliškumą",
    add_task_above: "Pridėti užduotį aukščiau",
    add_task_below: "Pridėti užduotį žemiau",
    delete_task: "Ištrinti",
    edit_task: "Redaguoti",
    select_vehicle_placeholder: "Pasirinkite automobilį",
    no_vehicles_text: "Nepavyko rasti automobilių",
    fuel_consumption_unit: 'l/100km',
    daily_odo: "Dienos ODO",
    daily_consumption: "K. sąnaudos",
    daily_average_speed: "Vid. greitis",
    'tacho_state_-1': "Poiilsis (be kortelės)",
    tacho_state_0: "Poilsis",
    tacho_state_1: "Pasirengęs dirbti",
    tacho_state_2: "Darbas",
    tacho_state_3: "Vairavimas",
    tacho_state_6: "Klaida",
    tacho_state_7: "Nepasiekiamas",
    tacho_state_missing: "Nėra duomenų",
    from: "Nuo",
    to: "Iki",
    odometer_from: "ODO (km) nuo",
    odometer_to: "ODO (km) iki",
    tacho_shift_time_left: "Liko iki darbo pabaigos",
    ends_at: "Baigiasi",
    time_left: "Liko laiko",
    rest_type: "Poiilsio tipas",
    rest_duration: "Poiilsio trukmė",
    tacho_rest_title_daily: "Dienos poiilsis",
    tacho_rest_title_weekly: "Savaitinis poiilsis",
    driving_time: "Vairavimo laikas",
    tacho_shift_title: "Darbo diena",
    tacho_chart: "Grafikas",
    tacho_details: "Duomenys",
    time_left_short: "Liko",
    tacho_details_current_activity: "Dabartinė veikla",
    tacho_details_activity: "Veikla",
    tacho_details_activity_start: "Veiklos pradžia",
    tacho_details_activity_duration: "Veiklos trukmė (iki dabar)",
    tacho_details_rest: "Poiilsis",
    tacho_details_last_rest_duration: "Paskutinio poiilsio trukmė",
    tacho_details_last_weekly_rest_duration: "Paskutinio savaitinio poilsio trukmė",
    tacho_details_shortened_daily_rest_times_count: "Sutrumpinto (9h) poilsio dienų sk. šią sav.",
    tacho_details_daily_rest_start: "Privaloma pradėti kasdienį poilsį",
    tacho_details_weekly_rest_start: "Privaloma pradėti kassavaitinį poilsį",
    tacho_details_driving_today: "Šiandienos vairavimas",
    tacho_details_driving_weekly: "Savaitinis vairavimas",
    tacho_details_shift_start: "Prasidėjo darbo diena",
    tacho_details_driving_period_start: "Vairavimo periodo pradžia",
    tacho_details_driving_period_driving_time: "Vairavimo trukmė šiuo periodu",
    tacho_details_driving_time_today: "Vairavimo trukmė šiandien",
    tacho_details_driving_time_this_week: "Vairavimo trukmė šią savaitę",
    tacho_details_driving_time_previous_week: "Vairavimo trukmė praeitą savaitę",
    tacho_details_driving_time_two_weeks: "Vairavimo trukmė per dvi savaites",
    tacho_details_extended_driving_times_count: "Prailginti (10h) vairavimai šią savaitę",
    tacho_details_work: "Kiti darbai",
    tacho_details_this_week: "Šią savaitę",
    tacho_details_today: "Šiandien",
    tacho_details_available: "Buvimas darbe",
    monday: 'Pirmadienis',
    tuesday: 'Antradienis',
    wednesday: 'Trečiadienis',
    thursday: 'Ketvirtadienis',
    friday: 'Penktadienis',
    saturday: 'Šeštadienis',
    sunday: 'Sekmadienis',
    january: 'Sausis',
    february: 'Vasaris',
    march: 'Kovas',
    april: 'Balandis',
    may: 'Gegužė',
    june: 'Birželis',
    july: 'Liepa',
    august: 'Rugpjūtis',
    september: 'Rugsėjis',
    october: 'Spalis',
    november: 'Lapkritis',
    december: 'Gruodis',
    shifts: 'Darbo dienos',
    tachograph: 'Tachografas',
    countries: 'Šalys',
    copy_coordinates: "Kopijuoti koordinates",
    open_street_view: 'Atidaryti sunkvežimio vietą "Street View"',
    add_via_marker: 'Prideti tarpinį tašką',
    sim: 'SIM',
    current_app_version: 'Dabartine versija',
    assigned_app_version: 'Priskirta versija',
    tacho_drive_time_left_until_break: "Liko vairuoti iki pertraukos",
    tacho_drive_time_left_today: "Liko vairuoti šiandien",
    violations: "Pažeidimai",
    tacho_violation_prolonged_driving_without_break: "Viršytas maksimalus (4h 30min) vairavimo laikotarpis be 45min pertraukos",
    tacho_violation_missed_daily_rest_start_deadline: "Per vėlai pradėtas kasdieninis poiilsis",
    tacho_violation_missed_weekly_rest_start_deadline: "Per vėlai pradėtas kassavaitinis poiilsis",
    tacho_violation_shortened_daily_rest_overuse: "Viršytas maksimalus (3) sutrumpintų (9h) kasdieninių poiilsių skaičius tarp dviejų kassavaitinių poiilsių",
    tacho_violation_too_short_weekly_rest: "Per trumpas kassavaitinis poilsis",
    tacho_violation_too_long_driving_period: "Viršytas maksimalus bendras vairavimo laikotarpis",
    tacho_violation_too_long_total_working_time: "Viršytas maksimalus bendras laikas",
    tacho_violation_extended_driving_time_overuse: "Viršytas maksimalus (2) prailgintų (10h) vairavimų skaičius per savaitę",
    tacho_violation_exceeded_maximum_one_week_driving_time: "Viršytas maksimalus (56h) savaitės vairavimo laikas",
    tacho_violation_exceeded_maximum_two_weeks_driving_time: "Viršytas maksimalus (90h) dviejų savaičių vairavimo laikas",
    tacho_violation_too_short_daily_rest: "Per trumpas dienos poiilsis",
    task_info_about_creation_template: "Sukurta {created_at} vartotojo {name}",
    task_planner: "Užduoties planavimas",
    address: "Adresas",
    info: "Informacija",
    additional_info: "Papildoma informacija",
    trip_number: "Kelionės numeris",
    arrival_time: "Atvykimo laikas",
    comment: "Komentaras",
    cargo: "Kroviniai",
    task_info_about_trip_number: "Kelionė: {trip_number}",
    task_info_about_task_id: "Užduoties numeris: {id}",
    task_info_about_arrival_time: "Atvykimo laikas: {arrival_time}",
    task_info_about_comment: "{comment}",
    task_photo_verification: "Foto patvirtinimas",
    task_photo_verification_side: "Priekabos šonas",
    task_photo_verification_back: "Priekabos galas",
    cargo_new: "Naujas krovinys",
    cargo_edit: "Redaguoti krovinį",
    cargo_view: "Krovinio peržiūra",
    cargo_identifier: "Pavadinimas",
    cargo_reference: "Nuoroda",
    cargo_amount: "Kiekis",
    cargo_amount_units: "vnt.",
    cargo_size: "Dydis",
    cargo_size_units: "LDM",
    cargo_unload_address: "Išsikrovimas",
    cargo_comment: "Komentaras",
    cargo_document_requests: "Dokumentai",
    cargo_document_requests_cmr: "CMR",
    cargo_document_requests_pll: "PLL Note",
    cargo_document_requests_delivery_note: "Delivery Note",
    cargo_add: "Sukurti krovinį",
    task_type_disabled_due_to_selected_cargo: "Negalima pakeisti tipo kol užduotis turi priskirtų krovinių",
    cargo_delete_disabled_due_to_loading: "Negalima ištrinti krovinio kuris jau priskirtas kitoje užduotyje",
    load: "Pasikrovimas",
    unload: "Išsikrovimas",
    edit_planned_cargo: "Redaguoti suplanuotus krovinius",
    edit: "Redaguoti",
    ldm: "LDM",
    close: "Uždaryti",
    cargo_does_not_fit_trailer: "Krovinys netelpa į priekabą",
    unload_address_copied: "Iškrovimo adresas nukopijuotas",
    view_planned_cargo: "Suplanuoti kroviniai",
    no_tacho_data_title: "Nėra tachografo duomenų",
    no_tacho_data_subtitle: "Šiuo metu neturime duomenų iš jokios tachografo kortelės",
    cargo_overload_title: "Perkrauta {size} LDM",
    cargo_underload_title: "Nedakrauta {size} LDM",
    cargo_overload_delete_title: "Ištrinti perkrovą",
    cargo_overload_delete_message: "Ar tikrai norite ištrinti {size} LDM perkrovą?",
    cargo_underload_delete_title: "Ištrinti nedakrovimą",
    cargo_underload_delete_message: "Ar tikrai norite ištrinti {size} LDM nedakrovimą?",
    confirm: "Patvirtinti",
    trailer_type: "Priekabos tipas",
    vehicle_search_placeholder: "Ieškokite pagal valst. numerius arba vairuotoją",
    no_tasks: "Nėra sukurtų užduočių",
    tacho_prolonged_drive_periods_description: "Šią savaitę panaudoti {value} praiilginti vairavimai (10H) iš {limit} galimų",
    tacho_shortened_daily_rest_periods_description: "Šią savaitę panaudoti {value} sutrumpinti poiilsiai (9H) iš {limit} galimų",
    tacho_weekly_rest_start_description: "Sekantį savaitinį poiilsį reikia pradėti ne vėliau kaip {value}",
    tacho_weekly_drive_time_description: "Šios savaitės vairavimo trukmė {value} iš {limit}. Liko {remaining} vairavimo.",
    tacho_biweekly_drive_time_description: "Bendra dviejų savaičių vairavimo trukmė {value} iš {limit}. Liko {remaining} vairavimo.",
    months: "Sausis_Vasaris_Kovas_Balandis_Gegužė_Birželis_Liepa_Rugpjutis_Rugsėjis_Spalis_Lapkritis_Gruodis",
    weekdays: "Sekmadienis_Pirmadienis_Antradienis_Trečiadienis_Ketvirtadienis_Penktadienis_Šeštadienis",
    cargo_empty_list_message: "Nėra sukurtų krovinių",
    cruise_control_active: "Autopilotas įjungtas",
    battery_voltage_low: "Žemas akumuliatoriaus voltažas: {value}V",
    engine_temperature_high: "Aukšta variklio temperatūra: {value}°",
    service_distance_low: "Serviso distancija: {value}km",
    outside_temperature_low: "Žema oro temperatūra: {value}°",
    outside_temperature_high: "Aukšta oro temperatūra: {value}°",
    tasks: "Užduotys",
    updated_task_attribute_destination: "adresą",
    updated_task_attribute_via: "tarpinius taškus",
    updated_task_attribute_type: "tipą",
    updated_task_attribute_trip_number: "kelionės numerį",
    updated_task_attribute_arrival_time: "FIX laiką",
    updated_task_attribute_comment: "komentarą",
    updated_task_attribute_photo_request_side: "foto patvirtinimą",
    updated_task_attribute_photo_request_back: "foto patvirtinimą",
    updated_task_attribute_attachment_id: "failą",
    updated_task_attribute_cargo: "krovinius",
    updated_task_attribute_ignore_traffic: "eismo sąlygas",
    updated_task_attribute_ignore_truck_restrictions: "sunkvežimių apribojimus",
    notification_task_deleted: "{user_name} ištrynė {task_type_text} užduotį {destination}.",
    notification_task_updated: "{user_name} pakeitė {task_type_text} užduotį {destination}.",
    notification_current_task_changed: "{user_name} pakeitė dabar vykdomą užduotį į {destination}.",
    notification_task_updated_with_attributes_list: "{user_name} pakeitė {updated_attributes_t} {task_type_text} užduotyje {destination}.",
    notification_cargo_size_overload: "Pakrauta {size} LDM daugiau nei buvo suplanuota",
    notification_cargo_size_underload: "Pakrauta {size} LDM mažiau nei buvo suplanuota",
    notification_cargo_amount_overload: "Krovinys buvo didesnis nei suplanuota. Suplanuota {expected_amount} vnt, pakrauta {actual_amount} vnt",
    notification_cargo_amount_underload: "Krovinys buvo mažesnis nei suplanuota. Suplanuota {expected_amount} vnt, pakrauta {actual_amount} vnt",
    notification_weight_axle_1_max: "1 ašies apkrovimas viršijo {limit}kg limitą (reikšmė: {value}kg)",
    notification_weight_axle_1_min: "1 ašies apkrovimas nukrito žemiau {limit}kg limito (reikšmė: {value}kg)",
    notification_weight_axle_2_max: "2 ašies apkrovimas viršijo {limit}kg limitą (reikšmė: {value}kg)",
    notification_weight_axle_2_min: "2 ašies apkrovimas nukrito žemiau {limit}kg limito (reikšmė: {value}kg)",
    notification_weight_axles_combined_max: "Bendras ašių apkrovimas viršijo {limit}kg limitą (reikšmė: {value}kg)",
    notification_weight_axles_combined_min: "Bendras ašių apkrovimas nukrito žemiau {limit}kg limito (reikšmė: {value}kg)",
    notification_weight_total_max: "Bendras svoris viršijo {limit}kg limitą (reikšmė: {value}kg)",
    notification_weight_total_min: "Bendras svoris nukrito žemiau {limit}kg limito (reikšmė: {value}kg)",
    notification_fuel_level_max: "Kuro lygis viršijo {limit}% limitą (reikšmė: {value}%)",
    notification_fuel_level_min: "Kuro lygis nukrito žemiau {limit}% limito (reikšmė: {value}%)",
    notification_adblue_level_max: "AdBlue lygis viršijo {limit}% limitą (reikšmė: {value}%)",
    notification_adblue_level_min: "AdBlue lygis nukrito žemiau {limit}% limito (reikšmė: {value}%)",
    notification_action_view: "Peržiūrėti",
    notification_action_show: "Peržiūrėti",
    notification_action_confirm: "Patvirtinti",
    license_plate: "Numeriai",
    trailer_license_plate: "Priekabos numeriai",
    vehicle_type_truck: "Sunkvežimis",
    vehicle_type_trailer: "Priekaba",
    trailer_type_ref: "Šaldytuvas",
    trailer_type_tent: "Tentinė",
    trailer_type_box: "Kietašonė",
    trailer_type_mega: "Mega",
    trailer_type_train: "Traukinys",
    trailer_type_tanker: "Cisterna",
    trailer_type_platform: "Negabaritinė",
    trailer_type_autotransporter: "Autovežis",
    chat_role_driver: "Vairuotojas",
    chats_list: "Pokalbiai",
    today: "Šiandien",
    yesterday: "Vakar",
    sent: "Išsiųsta",
    role: "Pareigos",
    login: "Prisijungti",
    seen: "Perskaityta",
    not_seen: "Neperskaityta",
    seen_by: "Perskaitė",
    seen_by_driver: "Perskaityta vairuotojo",
    not_seen_by_driver: "Neperskaityta vairuotojo",
    file: "Failas",
    add_file: "Pridėti failą",
    fast_chat_messages: "Greitosios žinutės",
    fast_chat_message: "Greitoji žinutė",
    new_fast_chat_message: "Nauja greitoji žinutė",
    fast_chat_questions: "Greitieji klausimai",
    fast_chat_question: "Greitasis klausimas",
    fast_chat_responses: "Atsakymai",
    new_fast_chat_question: "Naujas greitasis klausimas",
    new_fast_chat_response: "Naujas atsakymas",
    message_in_lt: "Žinutė (LT)",
    message_in_en: "Žinutė (EN)",
    message_in_ru: "Žinutė (RU)",
    task_type: "Užduoties tipas",
    fast_chat_intro_loading: "Vairuotojas yra pasikrovime",
    fast_chat_intro_unloading: "Vairuotojas yra išsikrovime",
    fast_chat_intro_fuel: "Vairuotojas yra degalinėje",
    fast_chat_intro_parking: "Vairuotojas yra parkinge",
    fast_chat_intro_trailer_change: "Vairuotojas yra priekabos perkabinime",
    fast_chat_is_active: "Aktyvi greita komunikacija",
    filter_by_vehicles: "Filtruoti pagal automobilius",
    filter_by_driver: "Filtruoti pagal vairuotoją",
    filter: "Filtruoti",
    driver: "Vairuotojas",
    download_date: "Parsiųsta",
    download_all_files: "Parsisiųsti visus failus",
    chart: "Grafikas",
    files: "Failai",
    driver_file: "Vairuotojo failas",
    vehicle_file: "Automobilio failas",
    results: "Rezultatai",
    company_card_is_connected: "Įmonės kortelė pasiekiama",
    company_card_is_disconnected: "Įmonės kortelė nepasiekiama",
    drivers: "Vairuotojai",
    no_data: "Nėra duomenų",
    download: "Parsisiųsti",
    month_abbreviation: "mėn.",
    task_type_loading: "Pasikrovimas",
    task_type_unloading: "Išsikrovimas",
    task_type_parking: "Parkingas",
    task_type_fuel: "Degalinė",
    task_type_trailer_change: "Priekabos perkabinimas",
    task_type_customs: "Muitinė",
    task_type_ferry: "Keltas",
    task_type_maintenance: "Servisas",
    task_type_reloading: "Persikrovimas",
    task_type_train: "Traukinys",
    task_type_wash: "Plovykla",
    task_finished_description_loading: "Pasikrovimo užduotis baigta",
    task_finished_description_unloading: "Išsikrovimo užduotis baigta",
    task_finished_description_parking: "Parkingo užduotis baigta",
    task_finished_description_fuel: "Degalinės užduotis baigta",
    task_finished_description_trailer_change: "Priekabos perkabinimo užduotis baigta",
    task_finished_description_customs: "Muitinės užduotis baigta",
    task_finished_description_ferry: "Kelto užduotis baigta",
    task_finished_description_maintenance: "Serviso užduotis baigta",
    task_finished_description_train: "Traukinio užduotis baigta",
    task_finished_description_wash: "Plovyklos užduotis baigta",
    task_finished_description_reloading: "Persikrovimo užduotis baigta",
    chat_sender_system: "Sistema, Vestigo",
    task_loaded_ldm_tooltip: "Pakrauta {loaded} LDM iš suplanuotų {planned} LDM",
    task_loaded_amount_tooltip: "Pakrauta {loaded} vnt. iš suplanuotų {planned} vnt.",
    cargo_info: "Krovinio informacija",
    trailer_side_photos: "Priekabos šono nuotraukos",
    trailer_back_photos: "Priekabos galo nuotraukos",
    cmr_photos: "CMR nuotraukos",
    pll_note_photos: "PLL note nuotraukos",
    delivery_note_photos: "Delivery note nuotraukos",
    no_cargo_selected_modal_title: "Nėra parinktų krovinių",
    no_cargo_selected_modal_description: "Ar tikrai norite tęsti nepasirinkę jokių krovinių šiai užduočiai?",
    delete_task_confirmation_modal_title: "Ištrinti užduodį",
    delete_task_confirmation_modal_description: "Ar tikrai norite ištrinti šią užduotį?",
    no: "Ne",
    yes: "Taip",
    page_title_new_messages: "Yra naujų žinučių",
    weekly_tacho_rest_tooltip: "Paskutinis savaitinio poiilsio trukmė: {duration}",
    weekly_tacho_rest_tooltip_no_data: "Nėra duomenų apie paskutinį savaitinį poiilsį",
    have_new_messages: "Yra naujų žinučių",
    have_messages_unread_by_driver: "Vairuotojas yra neperskaitęs žinučių ilgiau nei {time}",
    no_tacho_card_tooltip: "Nėra tachografo kortelės nuo: {date}",
    vehicles_count_tooltip: "Nėra sunkvežimių | Vienas sunkvežimis | {count} sunkvežimiai",
    loading_more_messages: "Kraunama daugiau žinučių...",
    chat_end: "Susirašinėjimo istorijos pabaiga",
    ignore_traffic_conditions: "Ignoruoti eismo sąlygas",
    ignore_truck_restrictions: "Ignoruoti sunkvežimių apribojimus",
    traffic_and_restrictions_disable_title: "Išjungti eismo sąlygas ir sunkvežimių apribojimus?",
    traffic_and_restrictions_disable_description: "Maršrutas su tarpiniais taškais gali būti netikslus, jeigu tarpiniai taškai bus keliuose kuriuos, normaliomis sąlygomis, navigacija stengtūsi apvažiuoti dėl eismo sąlygų ar sunkvežimių apribojimų. Mes rekomenduojame išjungti eismo sąlygas bei sunkvežimių apribojimus šiai užduočiai, jeigu norite maršrutą koreguoti savo nuožiūra.",
    traffic_and_restrictions_disable_confirm_title: "Taip, išjungti",
    traffic_and_restrictions_disable_cancel_title: "Ne, nekeisti šių nustatymų",
    delete_all_via_points: "Ištrinti visus",
    save_as_template: "Išsaugoti kaip šabloną",
    route_settings: "Maršruto nustatymai",
    save_via_points_as_template_title: "Išsaugoti kaip šabloną",
    via_points_template_title: "Šablono pavadinimas",
    via_points_count: "Tarpiniai taškai",
    via_points_templates: "Tarpinių taškų šablonai",
    apply: "Taikyti",
    delete: "Ištrinti",
    applied: "Pritaikyta",
    no_via_points_templates: "Nėra išsaugotų tarpninių taškų šablonų",
    task_via_points_count_tooltip: "Nėra tarpinių taškų | Maršrutas turi 1 tarpinį tašką | Maršrutas turi {count} tarpinius taškus",
    task_disabled_traffic_tooltip: "Eismo sąlygos išjungtos šiam maršrutui",
    task_disabled_truck_restrictions_tooltip: "Sunkvežimių apribojimai išjungti šiam maršrutui",
    created_by: "Sukūrė",
    delete_via_points_template_confirmation_modal_title: "Ištrinti tarpinių taškų šabloną",
    delete_via_points_template_confirmation_modal_description: "Ar tikrai norite ištrinti šį tarpinių taškų šabloną? Šablonas bus ištrintas visai imonei.",
    delete_cargo_confirmation_modal_title: "Ištrinti krovinį",
    delete_cargo_confirmation_modal_description: "Ar tikrai norite ištrinti šį krovinį?",
    allow_map_download: "Leisti žemėlapio parsiuntimą",
    tasks_unavailable_because_vehicle_has_no_nav_unit: "Užduotys nepasiekiamos, nes šiai transporto priemonei nėra priskirto navigacijos įrenginio",
    plan_route_from_location: "Planuoti maršrutą iš čia",
    plan_route_to_location: "Planuoti maršrutą į čia",
    delete_marker: "Ištrinti žymeklį",
    close_local_route_planner: "Išjungti maršruto planavimą",
    collapse_local_route_planner: "Suskleisti maršruto planavimą",
    expand_local_route_planner: "Išskleisti maršruto planavimą",
    local_route_planner: "Maršruto planavimas",
    adress_search: "Ieškoti adreso",
    all_messages: "Visos žinutes",
    speed_description: "Greitis: {speed} km/h",
    speed_with_limit_description: "Greitis: {speed} km/h\nLeistinas greitis: {speed_limit} km/h",
    notification_fast_chat_response: "Greitos komunikacijos atsakymas",
    notification_fast_chat_message: "Greitos komunikacijos žinutė",
    pick_organization_you_want_to_login_to: "Pasirinkite įmonę į kurią norite prisijungti:",
    invalid_credentials: "Neteisingas el. pašto adresas arba slaptažodis.",
    finish_task: "Užbaigti užduotį",
    finish_task_confirmation: "Ar tikrai norite užbaigti {destination} užduotį? Nuotraukos ir dokumentai, jei to prašoma užduotyje, nebus pateikti.",
    missing_photos: "Nepateiktos nuotraukos",
    task_finished_by: "Užduotį užbaigė",
    vehicle_list_trailer_type_description: "Puspriekabės tipas: {type}",
    vehicle_list_trailer_type_with_license_plate_description: "Puspriekabės tipas: {type}\nValst. numeriai: {license_plate}",
    vehicle_list_tasks_count_description: "Užduočių kiekis: {count}",
    vehicle_list_current_task_description: "Vykdoma užduotis: {type}\nLikęs nuvažiuoti atstumas: {distance}",
    vehicle_list_current_task_driving_duration_description: "Likęs važiavimo laikas: {duration}\n- Šis laikas skaičiuojamas nevertinant darbo poiilsio režimo.",
    vehicle_list_fix_arrival_time_description: "Fiksuotas atvykimo laikas: {time}",
    vehicle_list_fix_arrival_time_error_description: "- Sunkvežimis gali nespėti atvykti fiksuotu atvykimo laiku.",
    vehicle_list_tacho_driver_description: "Vairuotojas: {driver}",
    vehicle_list_tacho_weekly_driving_warning_description: "- Savaitės arba dviejų savaičių vairamo laiko liko mažiau nei 18val.",
    vehicle_list_tacho_weekly_driving_error_description: "- Savaitės arba dviejų savaičių vairamo laiko liko mažiau nei 9val.",
    vehicle_list_fuel_level_warning_or_error_description: "- Kuro lygis mažesnis nei {limit}%.",
    vehicle_list_adblue_level_warning_or_error_description: "- AdBlue lygis mažesnis nei {limit}%.",
    vehicle_list_arrived_at_loading_description: "Atvykęs į pasikrovimo vietą {time}.",
    vehicle_list_arrived_at_unloading_description: "Atvykęs į išsikrovimo vietą {time}.",
    vehicle_list_open_chat_description: "Atidaryti susirašinėjimą",
    vehicle_list_status_offline: "Sekimo įrenginys yra nepasiekiamas",
    vehicle_list_status_active: "Vykdo užduotį",
    vehicle_list_status_driving: "Juda",
    vehicle_list_status_ignition: "Degimas įjungtas",
    vehicle_list_status_inactive: "Nejuda",
    tasks_list_total_distance_description: "Visų užduočių atstumas",
    tasks_list_total_duration_description: "Visų užduočių važiavimo laikas",
    tasks_list_total_tolls_description: "Visų užduočių kelių mokesčių suma",
    tasks_list_fix_arrival_time_description: "Fiksuotas atvykimo laikas: {time}",
    tasks_list_distance_description: "Atstumas: {distance}",
    tasks_list_duration_description: "Važiavimo laikas: {duration}\n- Šis laikas skaičiuojamas nevertinant darbo poiilsio režimo.",
    tasks_list_eta_description: "Numatomas atvykimo laikas: {eta}\n- Šis laikas skaičiuojamas nevertinant darbo poiilsio režimo.",
    tasks_list_tolls_description: "Kelių mokesčiai: {tolls}",
    tasks_list_reorder_description: "Pakeisti eiliškumą",
    task_planner_type_title: "Tipas (privaloma)",
    task_planner_destination_title: "Pabaiga (privaloma)",
    task_planner_invalid_task_description: "Privaloma pasirinkti užduoties tipą ir kelionės adresą",
    cargo_document_request_description: "Užbaigiant užduotį vairuotojas turės pateikti pasirinktų dokumentų nuotraukas",
    vehicle_details_country_description: "Dabartinė šalis: {country}",
    vehicle_details_tag_description: "Žyma: {tag}",
    weight_axle_1_description: "Pirmos ašies svoris",
    weight_axles_combined_description: "Bendras sunkvežimio ašių svoris",
    weight_axles_2_description: "Antros ašies svoris",
    weight_total_description: "Bendras sąstato svoris\n- Reikšmė išskaičiuojama iš variklio apkrovos, todėl gali būti netiksli.",
    open_chat: "Atidaryti susirašinėjimus",
    effectiveness_of_managers: "Vadybininkų efektyvumas",
    managers: "Vadybininkai",
    manager: "Vadybininkas",
    select_all: "Pasirinkti visus",
    deselect_all: "Išvalyti pasirinkimą",
    avg_problem_reaction_time: "Reakcija į problemas",
    time_without_task: "Laikas be užduočių",
    avg_loading_time: "Krovimo trukmė",
    tasks_with_problems: "Užduotys su problemomis",
    avg_message_response_time: "Reakcija į žinutes",
    avg_fast_chat_message_response_time: "Reakcija į greitą komunikaciją",
    avg_fuel_consumption: "Kuro sąnaudos",
    avg_fuel_consumption_per_ton: "Kuro sąnaudos tonai",
    total_driving_duration: "Bendras vairavimo laikas",
    total_mileage: "Bendras kilometražas",
    summary: "Ataskaita",
    select_managers: "Pasirinkite vadybininkus",
    manager_summary: "Vadybininkų ataskaita",
    effectiveness_of_drivers: "Vairuotojų efektyvumas",
    select_drivers: "Pasirinkite vairuotojus",
    eco_driving: "ECO Vairavimas",
    tachograph_faults: "Tachografo klaidos",
    route_deviation: "Nukrypimai nuo maršruto",
    engine_idling_duration: "Variklio darbas tuščia eiga",
    fix_time_tasks_problem: "Paveluota atvykti į FIX užduotis",
    driver_summary: "Vairuotojų ataskaita",
    history: "Istorija",
    current_month: "Šis mėnuo",
    previous_month: "Praeitas mėnuo",
    truck: "Sunkvežimis",
    drive_interval: "Važiavimo intervalas",
    drive: "Važiavimas",
    stop: "Sustojimas",
    status: "Būsena",
    no_history_report_subtitle: "Pasirinkite sunkvežimį, periodą ir paspauskite mygtuką \"GENERUOTI\", kad sugeneruotumėt sunkvežimio istorijos duomenis",
    coordinates: "Koordinatės",
    history_start: "Istorijos pradžia",
    history_end: "Istorijos pabaiga",
    current_week: "Ši savaitė",
    previous_week: "Praeita savaitė",
    avg_problem_reaction_time_description: "<b>Reakcijos į problemas laikas</b><br/>Laikas nuo problemos atsiradimo iki kol vadybininkas į ją sureagavo.",
    time_without_task_description: "<b>Laikas be užduočių</b><br/>Laikas kiek vadybininko sunkvežimiai praleido be užduoties.",
    avg_loading_time_description: "<b>Vidutinė krovimo trukmė</b><br/>Laikas, kiek vidutiniškai vadybininko sunkvežimiai praleidžia išsikrovimo ir pasikrovimo užduotyse nuo atvykimo į vietą iki užduoties užbaigimo.",
    avg_message_response_time_description: "<b>Reakcijos į žinutes laikas</b><br/>Laikas, kiek vidutiniškai užtrunka perskaityti gautą žinutę.",
    total_mileage_description: "<b>Bendras kilometražas</b><br/>Bendras kilometražas per pasirinktą laiko periodą.",
    engine_idling_duration_description: "<b>Variklio darbas tuščia eiga</b><br/>Bendras variklio darbo tuščia eiga laikas.",
    avg_fast_chat_message_response_time_description: "<b>Reakcijos į greitą komunikaciją laikas</b><br/>Vidutinė greitos komunikacijos žinučių atsakymo trukmė.",
    avg_fuel_consumption_description: "<b>Kuro sąnaudos</b><br/>Kuro sąnaudos 100km.",
    avg_fuel_consumption_per_ton_description: "<b>Kuro sąnaudos tonai</b><br/>Šis parametras parodo kiek kuro sunkvežimis suvartojo pervežti 1t svorio 100km.",
    total_driving_duration_description: "<b>Bendras vairavimo laikas</b><br/>Bendras vairavimo laikas per pasirinktą periodą.",
    total_gps_distance: 'Bendras kilometražas (GPS)',
    total_odometer_distance: 'Bendras kilometražas (ODO)',
    total_fuel_consumed: 'Sunaudota kuro',
    total_gps_fuel_consumption: 'Kuro sąnaudos (GPS)',
    total_odometer_fuel_consumption: 'Kuro sąnaudos (ODO)',
    interval_start_odometer: 'Odometras nuo',
    interval_end_odometer: 'Odometras iki',
    total_gps_distance_description: '<b>Bendras kilometražas (GPS)</b><br/>Bendras kilometražas pagal GPS duomenis.',
    total_odometer_distance_description: '<b>Bendras kilometražas (ODO)</b><br/>Bendras kilometražas pagal odometro duomenis.',
    total_fuel_consumed_description: '<b>Sunaudota kuro</b><br/>Sunaudoto kuro kiekis. (L)',
    total_gps_fuel_consumption_description: '<b>Kuro sąnaudos (GPS)</b><br/>Vidutinės kuro sąnaudos pagal GPS duomenis. (l/100km)',
    total_odometer_fuel_consumption_description: '<b>Kuro sąnaudos (ODO)</b><br/>Vidutinės kuro sąnaudos pagal odometro duomenis. (l/100km)',
    interval_start_odometer_description: '<b>Odometras nuo</b><br/>Odometro parodymai intervalo pradžioje.',
    interval_end_odometer_description: '<b>Odometras iki</b><br/>Odometro parodymai intervalo pabaigoje.',
    view_period_summary: "Matyti periodo ataskaitas", 
    view_managers_report: "Matyti vadybininkų ataskaitas",
    view_drivers_report: "Matyti vairuotojų ataskaitas", 
    view_history: "Matyti sunkvežimių istoriją", 
    view_tachograph_files: "Matyti tachografo failus",
    manage_system_settings: "Valdyti sistemos nustatymus",
    manage_organization_settings: "Valdyti įmonės nustatymus",
    manage_users: "Valdyti vartotojus",
    manage_tasks: "Valdyti užduotis",
    view_trucks_map: "Matyti sunkvežimių žemėlapį",
    create_via_points_templates: "Kurti tarpinių taškų šablonus",
    delete_via_points_templates: "Trinti tarpinių taškų šablonus",
    use_chat: "Naudotis susirašinėjimu",
    hidden_seen_status: "Nerodyti, kad perskaitė žinutes",
    select_organization: "Pasirinkite įmonę",
    reset_password: "Atstatyti slaptažodį",
    reset_password_description: "Slaptažodžio atstatymo instrukcijos bus nusiųstos vartotojo el. paštu.",
    api_error_11001: "Nepavyko apskaičiuoti maršruto",
    api_error_11002: "Nepavyko rasti sunkvežimio",
    api_error_11003: "Nepavyko rasti susirašinėjimo",
    api_error_11004: "Nepavyko rasti žinutės",
    api_error_11005: "Nepavyko rasti užduoties",
    api_error_11006: "Nerasta duomenų pagal kelionės numerį",
    api_error_11007: "Neteisingas datų intervalas",
    api_error_11008: "Intervalas negali būti ilgesnis nei 90 dienų",
    api_error_11009: "Greitos komunikacijos klausimas turi turėti bet vieną atsakymą",
    api_error_11010: "Užduotis jau pradėta",
    api_error_11011: "Šiuo metu jau yra vykdomų užduočių",
    api_error_11012: "Prieiga negalima",
    mute_notifications: "Išjungti pranešimų garsus",
    unmute_notifications: "Įjungti pranešimų garsus"
  },
};

const i18n = createI18n({
  legacy: false,
  locale: "lt",
  globalInjection: true,
  warnHtmlMessage: false,
  messages,
});

export default i18n;
