export const parseErrorMessage = (errorData, translations) => {
    if (errorData.error) {
        if (errorData.error.code) {
            if (translations && translations?.te(`api_error_${errorData.error.code}`) == true) {
                return translations.t(`api_error_${errorData.error.code}`);
            } else {
                return errorData.error.message;
            }
        } else {
            return errorData.error.message;
        }
    } else {
        return errorData;
    }
}